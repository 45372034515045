<template>
  <div class="wallet">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
            class="col-md-10"
            style="display: flex;
    align-items: center;"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiWallet }}
            </v-icon>
            Wallet Balance: <strong class="ml-2">Ksh {{ balance }}</strong>
          </v-col>
          <v-col class="col-md-2">
            <refresh-button
              :loading="isWalletBalanceRefreshing"
              @click="getBalance()"
            />
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>
        <v-row>
          <v-col>
            Withdraw
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p>Withdraw from your wallet</p>
        <v-form
          v-model="isFormValid"
          @submit.prevent="withdraw"
        >
          <v-text-field
            v-model="form.amount"
            label="Amount"
            type="number"
            placeholder="Ksh 100"
            :rules="numberRules"
            outlined
          />
          <v-text-field
            v-model="form.phone_number"
            label="Phone Number"
            type="number"
            placeholder="07xxxxxxxx"
            :rules="phoneRules"
            outlined
          />
          <v-btn
            color="primary"
            class="mt-4"
            type="submit"
            :loading="form.busy"
            block
          >
            Withdraw
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>
        <v-row>
          <v-col>
            Withdrawal History
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="withdrawal_history"
          :loading="isWithdrawalHistoryLoading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:progress>
            <v-progress-linear
              indeterminate
              class="mt-2"
              rounded
            ></v-progress-linear>
            <p class="text-center mt-1">
              Loading...Please wait
            </p>
          </template>
          <template v-slot:body="{ items }">
            <tbody v-if="withdrawal_history.length > 0">
              <tr
                v-for="withdrawal_history in items"
                :key="withdrawal_history.id"
              >
                <td>
                  {{ withdrawal_history.amount }}
                </td>
                <td>
                  <v-chip
                    text-color="white"
                    :class="{
                      pending: withdrawal_history.transaction_status === 0,
                      approved: withdrawal_history.transaction_status === 1,
                      rejected: withdrawal_history.transaction_status === 2,
                    }"
                  >
                    {{ withdrawal_history.transaction_status | withdrawalStatusLabel }}
                  </v-chip>
                </td>
                <td>
                  {{ withdrawal_history.account_balance_after }}
                </td>
                <td>{{ withdrawal_history.created_at | formatDate }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-divider />
        <data-table-pagination
          :page="pagination.page"
          :page-count="pagination.pageCount"
          @page-change="onPageChange"
          @items-per-page-change="onItemsPerPageChange"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiWallet } from '@mdi/js'
import axios from 'axios'
import Form from 'vform'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import validationRules from '@/mixins/validationRules'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    RefreshButton,
    DataTablePagination,
  },
  filters: {
    withdrawalStatusLabel(status) {
      const statusLabels = {
        0: 'Pending',
        1: 'Approved',
        2: 'Rejected',
      }

      return statusLabels[status] || ''
    },
  },
  mixins: [validationRules],
  data() {
    return {
      balance: '0.00',
      isWalletBalanceRefreshing: false,
      isWithdrawalHistoryLoading: false,
      isFormValid: true,
      icons: {
        mdiWallet,
      },
      form: new Form({
        phone_number: '',
        amount: '',
      }),
      withdrawal_history: [],
      sortBy: ['created_at'],
      sortDesc: [true],
      headers: [
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Status',
          value: 'transaction_status',
        },
        {
          text: 'Account Balance',
          value: 'account_balance_after',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'created_at',
        }],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    statusChipColor() {
      return {
        0: 'grey',
        1: 'green',
        2: 'red',
      }
    },
  },
  watch: {
    refreshTable() {
      this.getWithdrawalHistory()
    },
  },
  mounted() {
    this.getBalance()
    this.getWithdrawalHistory()
  },
  methods: {
    withdraw() {
      if (this.isFormValid) {
        this.form.post('reseller/withdraw')
          .then(response => {
            this.$notification.success(response.data.message)
            this.getWithdrawalHistory()
            setTimeout(() => {
              this.getBalance()
            }, 2000)
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    getBalance() {
      this.isWalletBalanceRefreshing = true
      axios.get('reseller/balance')
        .then(response => {
          this.balance = response.data.balance
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
        .finally(() => {
          this.isWalletBalanceRefreshing = false
        })
    },
    getWithdrawalHistory() {
      this.isWithdrawalHistoryLoading = true
      axios.get(`reseller/withdrawal-history?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`)
        .then(response => {
          this.withdrawal_history = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
        .finally(() => {
          this.isWithdrawalHistoryLoading = false
        })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getWithdrawalHistory()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getWithdrawalHistory()
    },
  },

}
</script>

<style scoped>
.v-chip.rejected {
  background: #f44336;
}
.v-chip.approved {
  background: #4caf50;
}
.v-chip.pending {
  background: #ffa726;
}
</style>
